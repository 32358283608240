export const PRIVACY = [
  {
    title: "1. Introduction",
    text1: "This Policy provides an overview on how our Company protects the personal data and privacy of individuals who register to use our services (hereinafter referred to as the “Services”).",
    text2: "The Company is committed to protecting your privacy and handling your data in an open and transparent manner and in accordance with the European Regulation 2016/679 and the relevant data protection legislation which is applicable in Cyprus. The personal data that we collect and process depends on the product or service requested and agreed in each case.",
    text3: "Effective as of January 18, 2024, REXUS INTERNATIONAL LTD have updated the Privacy Policy (hereinafter referred to as the “Policy”).",
  },
  {
    title: "2. What we need",
    text1: "Our Personal Data Protection Policy governs the use and storage of your data. ",
    text2: "REXUS INTERNATIONAL LTD is a Controller of the personal data you provide to us. We only collect basic personal data about you.",
    text3: "Subscriber’s personal data (meaning any information about You from which You can be personally identified, such as Your name, telephone number or email address, and which is referred to in this Privacy Notice as \"Your Information\"), will be processed by REXUS INTERNATIONAL LTD (which include its agents and employees), and (where appropriate) our partners and subcontractors in order to be able to provide the full range of the Services to the Subscriber. The Subscriber’s information is collected when the Subscriber fills in a feedback form on the website https://rexusint.com/.",
  },
  {
    title: "3. Why we need it",
    text1: "We need your personal data in order to provide you with the following services: ",
    text2: "Contacting the person - collection of Authentication data: name, email, phone number for the purposes of contacting the person. ",
    text3: "Communicating the person - collection of email addresses for the purpose of communication with us.",
    text4: "Legal basis: Data Subject consent, conclusion and performance of the contract.",
  },
  {
    title: "4. Who receives your personal data",
    text1: "We disclose your personal data to persons authorized by us to process personal data (i.e., entities we contract to carry out various personal data processing operations on our behalf). ",
    text2: "Also, if the law obliges us or we have a legal basis to do so, we may disclose your personal data to third-party entities acting as controllers (entities that process data for their own purposes).",
  },
  {
    title: "5. Transfer of your personal data to a third country or to an international organization",
    text1: "Your personal data may be transferred to third countries [i.e. countries outside of the European Economic Area] according to the requirements of data protection legislation applicable.",
    text2: "Processors in third countries are obligated to comply with the European data protection standards and to provide appropriate safeguards in relation to the transfer of your data in accordance with GDPR Article 46.",
  },
  {
    title: "6. How long we keep your personal information for",
    text1: "All Personal Data will be kept in a format that allows the Data Subjects to be identified for no longer than is necessary for the purposes for which the personal data are processed.",
    text2: "Personal data may be stored for longer periods of time if personal data are processed solely for archiving purposes and for reasons of general interest, scientific or historical research or for statistical purposes or for the defense of any legal rights.",
  },
  {
    title: "7. Your data protection rights",
    text1: "You have the following rights, in terms of your personal data we hold about you:",
    list: [
      'Receive access to your personal data. This enables you to e.g. receive a copy of the personal data we hold about you and to check that we are lawfully processing it.',
      'Request correction of the personal data we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected.',
      'Request erasure of your personal information. This enables you to ask us to erase your personal data [known as the ‘right to be forgotten’] where there is no good reason for us continuing to process it.',
      'Object to processing of your personal data, where we are relying on a legitimate interest and there is something about your particular situation which makes you want to object to processing on this ground. If you lodge an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms.',
      'Withdrawal of the consent that you gave us with regards to the processing of your personal data at any time. Note that any withdrawal of consent shall not affect the lawfulness of processing based on consent before it was withdrawn or revoked by you.'
    ],
    text2: "In the event that you wish to complain about how we handled your personal data, please contact REXUS INTERNATIONAL LTD DPO by the following email address: info@rexusint.com.",
  },
  {
    title: "8. Right to lodge a complaint",
    text1: "If after complaining to the DPO you still feel that your Personal Data has not been handled appropriately, according to the law, you can lodge a complaint with Cyprus regarding the Processing of your Personal Data by us or on our behalf:",
    text2: "Office of the Commissioner for Personal Data Protection",
    text3: "Address: Iasonos 1, 1082 Nicosia, Cyprus",
    text4: "Email: commissioner@dataprotection.gov.cy"
  },
  {
    title: "9. Changes to this privacy statement",
    text1: "We may modify or amend this privacy statement from time to time.",
    text2: "We will notify you appropriately when we make changes to this privacy statement and we will amend the revision date at the top of this page. We do however encourage you to review this statement periodically so as to be always informed about how we are processing and protecting your personal information.",
  }
]