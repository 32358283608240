<template>
  <div class="privacy-content">
    <div class="container">
      <div class="flex flex-col">
        <div class="item" v-for="(item, index) in content" :key="index">
          <p class="item__title">{{ item.title }}</p>
          <p class="item__text">{{ item.text1 }}</p>
          <ul v-if="!!item?.list">
            <li v-for="i in item?.list" :key="i" class="item__text">{{ i }}</li>
          </ul>
          <p class="item__text" v-if="!!item.text2">{{ item.text2 }}</p>
          <p class="item__text" v-if="!!item.text3">{{ item.text3 }}</p>
          <p class="item__text" v-if="!!item.text4">{{ item.text4 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { PRIVACY } from "@/constant/privacy.js";

const content = ref(PRIVACY);
</script>

<style lang="scss" scoped>
.privacy-content {
  padding: 60px 0 110px;
  .container {
    & > div.flex {
      gap: 45px;
      max-width: 970px;
      width: 100%;
    }
  }
  .item {
    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; 
      margin-bottom: 14px;
    }
    &__text {
      opacity: 0.92;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    p + p {
      margin-top: 12px;
    }
    ul {
      margin: 12px 0 12px 20px;
    }
    li + li {
      margin-top: 4px;
    }
  }
}

@media (max-width: 768px) {
  .privacy-content {
    padding: 40px 0 60px;
  }
}
</style>